export const colours = {
    pink100: "#9f0051",
    pink200: "#b3005b",
    pink300: "#c70066",
    pink400: "#cc1975",
    pink500: "#d23284",
    pink600: "#d74c93",
    pink700: "#dd66a3",
    pink800: "#e37fb2",
    pink900: "#e899c1",
    pink1000: "#eeb2d1",
    red100: "#91201f",
    red200: "#aa2524",
    red300: "#c22b29",
    red400: "#d92826",
    red500: "#f33634",
    red600: "#f44a48",
    red700: "#f55e5c",
    red800: "#f77e7c",
    red900: "#f67270",
    red1000: "#ffcecc",
    red11000: "#f99a99",
    red12000: "#faaead",
    orange100: "#983701",
    orange200: "#b14101",
    orange300: "#cb4a01",
    orange400: "#e45301",
    orange500: "#fe5d02",
    orange600: "#fe6d1b",
    orange700: "#fe7d34",
    orange800: "#fe8d4d",
    orange900: "#fe9d67",
    orange1000: "#feae80",
    yellow100: "#977b0a",
    yellow200: "#b08f0b",
    yellow300: "#c9a40d",
    yellow400: "#e2b80f",
    yellow500: "#fccd11",
    yellow600: "#fcd228",
    yellow700: "#fcd740",
    yellow800: "#fcdc58",
    yellow900: "#fde170",
    yellow1000: "#fde688",
    green100: "#00612b",
    green200: "#007133",
    green300: "#00813a",
    green400: "#009141",
    green500: "#00a249",
    green600: "#19ab5b",
    green700: "#32b46d",
    green800: "#4cbd7f",
    green900: "#66c791",
    green1000: "#7fd0a4",
    blue100: "#015685",
    blue200: "#01649b",
    blue300: "#0173b1",
    blue400: "#0181c7",
    blue500: "#0290de",
    blue600: "#1b9be1",
    blue700: "#34a6e4",
    blue800: "#4db1e7",
    blue900: "#67bceb",
    blue1000: "#80c7ee",
    purple100: "#4a148c",
    purple200: "#6a1b9a",
    purple300: "#7b1fa2",
    purple400: "#8e24aa",
    purple500: "#9c27b0",
    purple600: "#ab47bc",
    purple700: "#ba68c8",
    purple800: "#ce93d8",
    purple900: "#e1bee7",
    purple1000: "#f3e5f5",
};

