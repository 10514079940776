import { colours } from "./colours";

export const MainTheme = {
    colours: {
        action100: colours.red100,
        action200: colours.red200,
        action300: colours.red300,
        action400: colours.red400,
        action500: colours.red500,
        action600: colours.red600,
        action700: colours.red700,
        action800: colours.red800,
        action900: colours.red900,
        action1000: colours.red1000,
        info100: colours.blue100,
        info200: colours.blue200,
        info300: colours.blue300,
        info400: colours.blue400,
        info500: colours.blue500,
        info600: colours.blue600,
        info700: colours.blue700,
        info800: colours.blue800,
        info900: colours.blue900,
        info1000: colours.blue1000,
        success100: colours.green100,
        success200: colours.green200,
        success300: colours.green300,
        success400: colours.green400,
        success500: colours.green500,
        success600: colours.green600,
        success700: colours.green700,
        success800: colours.green800,
        success900: colours.green900,
        success1000: colours.green1000,
        warning100: colours.orange100,
        warning200: colours.orange200,
        warning300: colours.orange300,
        warning400: colours.orange400,
        warning500: colours.orange500,
        warning600: colours.orange600,
        warning700: colours.orange700,
        warning800: colours.orange800,
        warning900: colours.orange900,
        warning1000: colours.orange1000,
        primary100: colours.pink100,
        primary200: colours.pink200,
        primary300: colours.pink300,
        primary400: colours.pink400,
        primary500: colours.pink500,
        primary600: colours.pink600,
        primary700: colours.pink700,
        primary800: colours.pink800,
        primary900: colours.pink900,
        primary1000: colours.pink1000,
        highlight100: colours.yellow100,
        highlight200: colours.yellow200,
        highlight300: colours.yellow300,
        highlight400: colours.yellow400,
        highlight500: colours.yellow500,
        highlight600: colours.yellow600,
        highlight700: colours.yellow700,
        highlight800: colours.yellow800,
        highlight900: colours.yellow900,
        highlight1000: colours.yellow1000,
    },
};
